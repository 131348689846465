import React, {useState, useContext, useEffect, useRef} from 'react';
import {Context} from '../../AppContext';
import {Steps, Result, message, Alert} from 'antd';
import {Button, Input, Heading, Row, Label, Text} from '../../Widgets';
import {formatValidator} from '../../Utils';
import LoginModal, {metadata as modalMetadata} from '../../Modals/Login';
import ContentWrapper from '../../Components/Layout/ContentWrapper';
import {ErrRegister} from '../../errors';
import {navigate} from 'gatsby';
const queryString = require('query-string');
const appConfig = require('../../data.json');

const {isNotEmpty, isEmail} = formatValidator;

const TIME_RESET = 120; //s

const UISTATE = {
  valid_email: 0,
  validating: 1,
  registering: 2,
  registered: 3,
};
const steps = ['驗證信箱', '點擊驗證信', '輸入密碼', '完成註冊'];

function errorHandler(err) {
  console.warn(err);
  if (err instanceof ErrRegister) {
    message.warning(err.message);
  } else if (err && err.error === 'user_exists') {
    message.warning('此信箱已有存在帳號！');
  } else if (err && err.error === 'invalid_token') {
    message.warning('此連結已過期，請重新寄送驗證信');
  } else if (err && err.detail && err.detail.indexOf('last') >= 0) {
    message.warning(`${TIME_RESET}秒內，無法再次寄送驗證信`);
  } else {
    message.error('發生錯誤');
  }
}

export default function Register({location}) {
  const [uiState, setUiState] = useState(UISTATE.valid_email);
  const [values, setValues] = useState({
    email: '',
    password: '',
    password2: '',
  });
  const [access_token, setAccessToken] = useState();
  const [time, setTime] = useState(0);
  const timer = useRef();

  const app = useContext(Context);
  const {profile} = app.state;

  useEffect(() => {
    if (profile) {
      navigate('/profile');
    }
  }, [profile]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const {access_token} = parsed; //validation_token
    if (access_token) {
      setAccessToken(access_token);
      setUiState(UISTATE.registering);
      app.actions.setLoading(false);
    }
  }, [location, app.actions]);

  useEffect(() => {
    if (time === TIME_RESET) {
      if (timer.current) {
        clearInterval(timer.current);
      }

      timer.current = setInterval(() => {
        if (time <= 0) {
          clearInterval(timer.current);
        }

        setTime((pre) => pre - 1);
      }, 1000);
    }

    if (time === 0) {
      return () => clearInterval(timer.current);
    }
  }, [time]);

  const register = async () => {
    app.actions.setLoading(true);

    try {
      if (!isNotEmpty(values.password) || !isNotEmpty(values.password2)) {
        throw new ErrRegister('密碼不可為空白');
      }

      if (values.password !== values.password2) {
        throw new ErrRegister('密碼需要確認密碼一致');
      }

      await app.actions.register({
        access_token,
        password: values.password,
      });

      setUiState(UISTATE.registered);
    } catch (ex) {
      errorHandler(ex);
    }
    app.actions.setLoading(false);
  };

  const validEmail = async () => {
    app.actions.setLoading(true);

    try {
      if (!isNotEmpty(values.email)) {
        throw new ErrRegister('信箱不可為空白');
      }

      if (!isEmail(values.email)) {
        throw new ErrRegister('信箱格式錯誤');
      }

      await app.actions.validEmail({
        email: values.email,
      });
      setTime(TIME_RESET);
      setUiState(UISTATE.validating);
    } catch (ex) {
      errorHandler(ex);
    }

    app.actions.setLoading(false);
  };

  return (
    <ContentWrapper>
      <div style={{maxWidth: 600, margin: '0 auto'}}>
        <Heading size="lg" align="center">
          註冊
        </Heading>
        <Steps current={uiState} style={{marginBottom: 30}}>
          {steps.map((step, idx) => (
            <Steps.Step
              key={step}
              title={
                <Text
                  size="lg"
                  weight={500}
                  color={
                    uiState === idx
                      ? appConfig.colors.text
                      : appConfig.colors.textThird
                  }>
                  {step}
                </Text>
              }
            />
          ))}
        </Steps>

        {(() => {
          switch (uiState) {
            case UISTATE.valid_email:
              return (
                <>
                  <Heading>驗證信箱</Heading>

                  <div>
                    <Label>電子信箱</Label>
                    <Input
                      id="register-email-input"
                      value={values.email}
                      onChange={(e) => {
                        let value = e.target.value;
                        setValues((prev) => ({...prev, email: value}));
                      }}
                    />
                  </div>

                  <Row margin="20px 0" style={{justifyContent: 'flex-end'}}>
                    <Button disabled={time > 0} onClick={() => validEmail()}>
                      寄送驗證信
                    </Button>
                  </Row>
                </>
              );
            case UISTATE.validating:
              return (
                <>
                  <Heading>已寄送驗證信</Heading>
                  <div>
                    <Label>電子信箱</Label>
                    <Input
                      id="register-email-input"
                      value={values.email}
                      onChange={(e) => {
                        let value = e.target.value;
                        setValues((prev) => ({...prev, email: value}));
                      }}
                      style={{margin: 0}}
                    />
                  </div>
                  <div style={{marginBottom: 10, color: '#999'}}>
                    請至信箱 {values.email}{' '}
                    查看是否收到驗證信，若沒有收到驗證信，可點選按鈕再次寄出。{' '}
                  </div>
                  <Alert
                    message="收到驗證信後，請點選「連結」跳回註冊頁，完成註冊程序"
                    type="info"
                    style={{margin: '10px 0'}}
                  />
                  <Row margin="20px 0" style={{justifyContent: 'flex-end'}}>
                    {time > 0 && (
                      <div style={{color: '#999', padding: 15}}>
                        {time} 秒後，可重寄驗證信
                      </div>
                    )}
                    <Button disabled={time > 0} onClick={() => validEmail()}>
                      寄送驗證信
                    </Button>
                  </Row>
                </>
              );
            case UISTATE.registering:
              return (
                <>
                  <h2>輸入密碼</h2>
                  <div>
                    <Label>密碼</Label>
                    <Input
                      id="register-password-input"
                      type="password"
                      value={values.password}
                      onChange={(e) => {
                        let value = e.target.value;
                        setValues((prev) => ({...prev, password: value}));
                      }}
                    />
                  </div>

                  <div>
                    <Label>確認密碼</Label>
                    <Input
                      id="register-password-input-confirm"
                      type="password"
                      value={values.password2}
                      onChange={(e) => {
                        let value = e.target.value;
                        setValues((prev) => ({
                          ...prev,
                          password2: value,
                        }));
                      }}
                    />
                  </div>

                  <Row margin="20px 0">
                    <Button
                      type="default"
                      onClick={() => setUiState(UISTATE.validating)}>
                      上一步{' '}
                    </Button>
                    <div style={{flex: 1}} />
                    <Button onClick={register}>註冊 </Button>
                  </Row>
                </>
              );
            case UISTATE.registered:
              return (
                <Result
                  title="註冊成功!"
                  subTitle={<Text>請登入，並前往會員中心填寫會員基本資料</Text>}
                  status="success"
                  extra={[
                    <Button
                      onClick={() => {
                        app.actions.setModal({
                          content: <LoginModal />,
                          ...modalMetadata,
                        });
                      }}>
                      登入
                    </Button>,
                  ]}
                />
              );
            default:
              return null;
          }
        })()}
      </div>
    </ContentWrapper>
  );
}
